<template>
    <div class="map-serv-content">
        <div class="map-serv-border">
            <div :id="'equip-mapidbox' + mapid" class="equip-mapbox"></div>
        </div>
    </div>
</template>
<script>
import "@libs/MousePosition.js";
import { mapActions } from "vuex";
const longrg =
    /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,6})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,6}|180)$/; // 经度正则验证
const latreg = /^(\-|\+)?([0-8]?\d{1}\.\d{0,6}|90\.0{0,6}|[0-8]?\d{1}|90)$/; // 纬度正则验证
export default {
    name: "equip-MapMkt",
    props: {
        mapInfoStr: {
            type: String,
        },
    },
    data() {
        return {
            mymap: null,
            mapid: Math.random(),
            mapInfoObj: {
                URL: "", // 地图URL
                ZXD: "", // 中心点
                ZSJB: "", // 缩放级别
            },
            mapboxMap: "",
        };
    },
    watch: {
        mapInfoStr() {
            if (this.mapInfoStr) {
                this.changeMapInfo();
            }
        },
    },
    mounted() {
        this.initMap();
    },
    methods: {
        ...mapActions(["getVectorStyle"]),
        // 初始化地图
        initMap() {
            let id = `equip-mapidbox${this.mapid}`;
            this.mymap = L.map(id, {
                crs: L.CRS.EPSG3857,
                attributionControl: false,
                zoomSnap: 0.1,
                zoomDelta: 0.1,
                minZoom: 11,
                maxZoom: 17,
                zoomControl: false,
                closePopupOnClick: true,
                renderer: L.svg(),
            });
        },
        changeMapInfo() {
            this.mapInfoObj = JSON.parse(this.mapInfoStr);
            if (this.mapInfoObj.ZXD) {
                let jwdArr = this.mapInfoObj.ZXD.split(",");
                if (jwdArr && jwdArr.length === 2 && jwdArr[0] && jwdArr[1]) {
                    let lat = jwdArr[0];
                    let lng = jwdArr[1];
                    if (longrg.test(lng) && latreg.test(lat)) {
                        if (this.mapInfoObj.ZSJB) {
                            this.mymap.setView(
                                [lat, lng],
                                this.mapInfoObj.ZSJB
                            );
                            this.initLayers();
                        }
                    } else {
                        this.common.showMsg("请填写正确的经纬度", "warning");
                    }
                } else {
                    this.common.showMsg("请填写正确的经纬度", "warning");
                }
            }
        },
        async initLayers() {
            const info = this.mapInfoObj;
            const url = info.URL || "";
            const mymap = this.mymap;
            if (!url) {
                this.common.showMsg("该图层url无效", "warning");
                return;
            }
            switch (info.MAPTYPE) {
                case "0": //WMS 类型的地图 的展示，主要为天地图
                    if (!info.TCMC) {
                        this.common.showMsg("该影像图层不正确", "warning");
                    }
                    window.L.tileLayer(
                        `${url}?T=${info.TCMC}&x={x}&y={y}&l={z}&tk=${tk}`,
                        {
                            minZoom: 3,
                            maxZoom: 17,
                            crs: L.CRS.EPSG3857,
                            showName: "底图",
                            showType: "底图",
                        }
                    ).addTo(mymap);
                    break;
                case "1": // WMTS 类型的地图 的展示
                    const layer = info.TCMC;
                    if (!layer) {
                        this.common.showMsg("该影像图层不正确", "warning");
                        return;
                    }
                    let ign = new window.L.tileLayer(`${url}`);
                    window.L.layerGroup([ign]).addTo(mymap);
                    let layerStyle1 = await this.getVectorStyle({
                        url: window.MAP_ZTT,
                    });
                    let list = [];
                    layerStyle1.layers.map((item, index) => {
                        if (item["source-layer"] == "遗产区划线") {
                            list.push(item);
                        }
                    });
                    layerStyle1.layers = list;
                    let allProvince1 = window.L.mapboxGL({
                        accessToken: window.MAPBOX_TOKEN,
                        style: layerStyle1,
                    }).addTo(mymap);
                    this.dealVectorLayers();
                    // 4.天地图影像地图注记
                    new window.L.TileLayer.WMTS(
                        `http://t{s}.tianditu.gov.cn/cia_w/wmts?tk=${tk}`,
                        {
                            zIndex: 4,
                            tileSize: 256,
                            layer: "cia",
                            style: "default",
                            tilematrixSet: "w",
                            crs: L.CRS.EPSG3857,
                            format: "tiles",
                            subdomains: "01234567",
                            minZoom: 3,
                            maxZoom: 17,
                            isBaseLayer: true,
                        }
                    ).addTo(mymap);
                    this.common.showVectorLayers();
                    break;
                case "2": // 矢量切片 的展示
                    let layerStyle = await this.getVectorStyle({
                        url,
                    });
                    window.L.mapboxGL({
                        accessToken: MAPBOX_TOKEN,
                        style: layerStyle,
                        // epsg: "EPSG:4490",
                        // transformRequest: (url, resourceType) => {
                        //     return {
                        //         url: url.replace("http", "https"),
                        //     };
                        // },
                    }).addTo(mymap);
                    this.dealVectorLayers();
                    break;
            }
        },
        // 修改矢量切片服务加载后的样式，达到叠加多个地图的功能
        dealVectorLayers() {
            let vectorLayers = document.querySelectorAll(".mapboxgl-map");
            vectorLayers.forEach((layer) => {
                layer.style.position = "absolute";
                layer.style.left = 0;
                layer.style.top = 0;
            });
        },
    },
};
</script>

<style scoped>
.map-serv-content {
    width: 100%;
    height: 100%;
    padding: 5px;
    border: 1px solid #a1a1a1;
}
.map-serv-border {
    width: 100%;
    height: 100%;
    padding: 1px;
    border: 3px solid #393938;
}
.equip-mapbox {
    position: absolute;
    top: 9px;
    left: 9px;
    bottom: 9px;
    right: 9px;
}
</style>
