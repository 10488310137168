<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <div class="button_list">
                    <sn-button :snButton="snButton" @handleChange="handleChange"></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form ref="formdata" :inline="true" :model="formdata.saveJson" class="demo-form-inline form_row" size="small" label-width="200px" :rules="rules">
                <el-form-item label="地图名称" prop="MC">
                    <el-input v-model="formdata.saveJson.MC" placeholder="请输入地图名称" :maxlength="50" onkeydown="if(event.keyCode==32) return false" :disabled="!isEdit"></el-input>
                </el-form-item>
                <el-form-item label="URL" prop="URL">
                    <el-input v-model="formdata.saveJson.URL" placeholder="请输入URL" @change="changeMapInfo" :maxlength="255" onkeydown="if(event.keyCode==32) return false" :disabled="!isEdit"></el-input>
                </el-form-item>
                <el-form-item label="地图类型" prop="MAPTYPE">
                    <el-select v-model="formdata.saveJson.MAPTYPE" placeholder="请选择地图类型" :disabled="!isEdit">
                        <el-option v-for="(item,index) in dtlxList" :key="`${item.Code}-${index}`" :label="item.Name" :value="item.Code"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="showTCMC" label="图层名称" prop="TCMC">
                    <el-input v-model="formdata.saveJson.TCMC" placeholder="示例 s:yuansd" :maxlength="50" onkeydown="if(event.keyCode==32) return false" :disabled="!isEdit" @change="changeMapInfo"></el-input>
                </el-form-item>
                <el-form-item label="默认缩放级别" prop="ZSJB">
                    <el-select v-model="formdata.saveJson.ZSJB" placeholder="请选择默认缩放级别" @change="changeMapInfo" :disabled="!isEdit">
                        <el-option v-for="(item,index) in zsjbList" :key="`${item.value}-${index}`" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="默认中心点" prop="ZXD">
                    <div class="flex">
                        <el-input v-model="formdata.saveJson.ZXD" placeholder="示例：42.357589,116.17958" :maxlength="50" onkeydown="if(event.keyCode==32) return false" :disabled="!isEdit" @change="changeMapInfo"></el-input>
                        <el-button @click="showPickup = true">地图选点</el-button>
                    </div>
                </el-form-item>
                <el-form-item label="类别" prop="SERVICECODE">
                    <el-select v-model="formdata.saveJson.SERVICECODE" placeholder="请选择类别" :disabled="!isEdit" @change="HandleLbChanged">
                        <el-option v-for="(item,index) in lbList" :key="`${item.CODE}-${index}`" :label="item.NAME" :value="item.CODE"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注" prop="REMARK">
                    <el-input v-model="formdata.saveJson.REMARK" placeholder="请输入备注" :maxlength="50" onkeydown="if(event.keyCode==32) return false" :disabled="!isEdit"></el-input>
                </el-form-item>
                <el-form-item label="地图发布时间" prop="DTFBSJ">
                    <el-date-picker
                        v-model="formdata.saveJson.DTFBSJ"
                        type="month"
                        :disabled="!isEdit"
                        value-format="yyyy-MM"
                        placeholder="请选择地图发布时间"
                    >
                    </el-date-picker>
                </el-form-item>
                <div class="map-serv-base">
                    <label class="map-serv-label">地图显示</label>
                    <MapserviceManageMap class="lonlat-map" mapSize="other" :mapInfoStr="mapInfoStr"></MapserviceManageMap>
                </div>
                <coordinatePickup
                    :showPickupP.sync="showPickup"
                    :coordinate.sync="coordinate"
                    @clickPoint="clickPoint"
                ></coordinatePickup>
            </el-form>
        </div>
    </div>
</template>

<script>
import coordinatePickup from "@comp/map/coordinate_pickup";
import snButton from "../edit-button.js";
import infoMixin from "../info_mixin.js";
import { mapActions } from "vuex";
import MapserviceManageMap from "./mapservice_manage_map";
export default {
    name: "mapservice_manage_form",
    mixins: [infoMixin],
    data() {
        return {
            showTCMC: false,
            coordinate: null,
            showPickup: false,
            notNeedFile: true,
            snButton: snButton,
            // 根据承诺事项来源类型不同掉服务获取承诺事项来源列表
            cnwcsj: false,
            cnwcsj_date: "",
            formdata: {
                saveJson: {
                   MC: "",
                    URL: "",
                    ZXD: "",
                    ZSJB: "",
                    MAPTYPE: "",
                    SERVICECODE: "",
                    SERVICETYPE: "",
                    REMARK: "",
                    DTFBSJ: "",
                    TCMC: ""
                },
                heritageId: "",
                itemID: "8020513",
                userName: "",
                userId: ""
            },
            zsjbList: [], // 展示级别
            dtlxList: [], // 地图类型
            lbList: [], // 类别
            itemID: "8020513",

            taskMap: null,
            mapInfoStr: "",
            rules: {
                MC: [
                    { required: true, message: '请输入地图名称', trigger: 'blur' },
                ],
                URL: [
                    { required: true, message: '请输入URL', trigger: 'blur' }
                ],
                ZXD: [
                    { required: true, message: '请输入默认中心点', trigger: 'blur' }
                ],
                ZSJB: [
                    { required: true, message: '请选择默认缩放级别', trigger: 'change' }
                ],
                MAPTYPE: [
                    { required: true, message: '请选择地图类型', trigger: 'change' }
                ],
                SERVICECODE: [
                    { required: true, message: '请选择类别', trigger: 'change' }
                ],
                DTFBSJ: [
                    { required: true, message: '请选择地图发布时间', trigger: 'change' }
                ],
                TCMC: [
                    { required: true, message: '请输入图层名称', trigger: 'change', }
                ],
            }
        };
    },
    components: {
        MapserviceManageMap,
        coordinatePickup
    },
    watch: {
        'formdata.saveJson.MAPTYPE': {
            deep: true,
            handler: function (type) {
                // 当选择 矢量切片 类型的 地图时候，TCMC 不必传
                if (String(type) === '2') {
                    this.rules.TCMC[0].required = false;
                    this.showTCMC = false;
                    this.formdata.saveJson.TCMC = '';
                }
                else {
                    this.rules.TCMC[0].required = true;
                    this.showTCMC = true;
                }
            }
        }
    },
    mounted() {
        this.setSfjbList();
        this.getDtlxList();
        this.getLxList();
    },
    methods: {
        ...mapActions([
            "GetEnumitemMap",
            "GetMapCategoryList",
            "GetDetailsData"
        ]),
        setSfjbList() {
            let arr = Array.from({ length: 18 }, (ele, i) => ({
                value: (i + 1).toString(),
                label: (i + 1).toString()
            }));
            this.zsjbList = arr;
        },
        async getDtlxList() {
            this.dtlxList = [];
            let result = await this.GetEnumitemMap({
                Enumid: "490c5fbb-5f43-11ea-bd8d-000c2977b7fd"
            });
            this.dtlxList = result;
        },
        async getLxList() {
            this.lbList = [];
            let result = await this.GetMapCategoryList();
            this.lbList = result;
        },
        async getFromData() {
            let params = {
                itemid: this.formdata.itemID,
                ID: this.id
            };
            let result = await this.GetDetailsData(params);
            if (result && result.length > 0) {
                Object.assign(this.formdata.saveJson, result[0]);
                this.changeMapInfo();
            }
        },
        HandleLbChanged(val) {
            let _this = this;
            this.formdata.saveJson.SERVICETYPE = "";
            if (this.lbList && this.lbList.length > 0) {
                this.lbList.forEach(item => {
                    if (item.CODE === val) {
                        _this.formdata.saveJson.SERVICETYPE = item.NAME;
                    }
                });
            }
        },
        changeMapInfo() {
            this.mapInfoStr = JSON.stringify(this.formdata.saveJson);
        },
        clickPoint() {
            this.formdata.saveJson.ZXD = this.coordinate.split(',').map(ele => String(Number(ele).toFixed(6))).reverse().join();
        },
    }
};
</script>
<style lang="scss" scoped>
.map-serv-base {
    width: 100%;
    height: 600px;
    margin-top: 20px;
    display: flex;
    flex-flow: row nowrap;
}

.map-serv-label {
    width: 200px;
    padding-right: 12px;
    font-weight: 700;
    text-align: right;
}

.lonlat-map {
    position: relative;
    width: 100%;
    height: 100%;
}
</style>